import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairExtensionforMen = () => {

  
  const seoData = {
    title: 'Hair Extensions for Men in Delhi - Radiance Hair Studio',
    description: 'Explore the ultimate solution for hair extensions for men in Delhi at Radiance Hair Studio. Your one-stop destination for natural-looking transformations.',
    keywords: ['Hair Extensions for men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": "https://www.radiancehairstudio.com/overlay7.png",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/hair-extensions-for-men.php",
    "description": "Explore the ultimate solution for hair extensions for men in Delhi at Radiance Hair Studio. Your one-stop destination for natural-looking transformations.",
    "category": "Hair Extension",
    "disambiguatingDescription": "Hair Extensions for men in Delhi",
    "mainEntityOfPage": "https://www.radiancehairstudio.com/hair-extensions-for-men.php",
    "serviceType": "Hair Extension for Men's"
  };

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Extension-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Extension for Men's</h2>
          <p>
          Since we were young, we have wanted to have long hair because we have seen our
           grandparents enjoying their gorgeous mane. It seems that the ideasfor implementation of LIFESTYLE and <a href="/gallery.php" style={{ color: "#373737"}}>FASHION </a>senses were different. But the DESIRE didn't take a LEAVE! To all our hair goals HAIR EXTENTION is the key. 
          </p>
  
          <h2>Making it Clear!</h2>
          <p>
          The BEAUTY industry does not lack BRAINS! It solved all one of our overnight hair goals.
          Our current mane gains volume and length thanks to the brand-new HAIR EXTENSION. RADIANCE has a lot to offer! beginning with a range of hair EXTENSION techniques to accommodate various hair lengths, colours, and types. RADIANCE is a one-stop shop for all of your hair needs. 

          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-extensions-for-men1.png",
      largeImg: "/hair-extensions-for-men2.png",
      content: (
        <div>
          <p>
            Speaking about the various forms of hair extension that RADIANCE SALON offers, here are a few. 
          </p>
          <p>At Radiance Salon, we take pride in offering a variety of top-quality hair extension options that cater to different needs and styles. Here's a glimpse of the options <a href="/contact.php" style={{ color: "#373737"}}> available:</a></p>
          <ul>
            <li>
              <strong>Tape-In Hair Extensions</strong> - The Tape-In Hair Extension is one of the easiest and most effective ways to achieve stunning hair. Made with human hair, it offers a seamless and natural look. Simply style it as you wish and enjoy gorgeous, voluminous hair for months on end.
            </li>
            <li>
              <strong>Weaving</strong> - Weaving is one of the hottest trends in the world of hair extensions today. This method is completely hassle-free, offering fuller, voluminous hair without the use of glue or heat. It’s the perfect option for those who want to enhance their natural hair with minimal effort.
            </li>
            <li>
              <strong>Micro Ring Extensions</strong> - The Micro Ring method is ideal for those who already have long hair and wish to add extra volume. This technique uses small rings to securely attach the extensions, giving your hair a lush, thick look. With no heat and no glue, it's a safe option that ensures beautiful, long-lasting results.
            </li>
            <li>
              <strong>Clip-On Extensions</strong> - Looking for a quick hair transformation? The Clip-On method is the go-to choice. It helps you achieve a thick, full mane in no time. This is the easiest and most versatile option for anyone looking to add volume or length to their hair for a special occasion or everyday glam.
            </li>
          </ul>
          <p>
          Each of these techniques uses 100% natural human hair extensions, ensuring a soft, luxurious look and feel. At Radiance Salon, we’re committed to giving our customers the best quality hair extensions to help boost confidence and transform your look. Your hair is your crown, and Radiance Salon helps you wear it proudly, reflecting your character and beauty. A healthy transformation is just a visit away!
          </p>
          <h2>Benefits of Human Hair Extensions : Why They’re the Perfect Choice</h2>
          <p>
          At Radiance, we hear you! You’ve always dreamt of having thick, luscious hair, but you’re not a fan of the artificial look that synthetic extensions often create. Well, it’s time to leave those worries behind! The beauty industry has evolved, offering natural human hair extensions that blend seamlessly with your own hair. Here’s why they’re a game-changer.
          </p>
          <p>
          Radiance’s human hair extensions are made from 100% natural hair and come in a wide variety of colors, making it easy to find the perfect match for your style. These extensions don’t look fake—they blend effortlessly into your natural hair, giving you a gorgeous, authentic look that feels like a queen wearing her crown.
          </p>
          <ul>
            <li>
            <strong>Unleash New Styling Possibilities -</strong>
            Tired of your same old hair? Human hair extensions are here to save the day! They add the volume and length you’ve always wanted, giving you the freedom to try out different styles whenever you wish. Whether you're looking for sleek and sophisticated or voluminous and playful, extensions offer endless styling possibilities.
            </li>
            <li>
  <strong>Color Your Dream Crown -</strong>
  Ever imagined rocking a colorful mane? With Radiance’s human hair extensions, achieving your dream color has never been easier. Whether you’re going for a bold, vibrant hue or a soft, subtle shade, coloring these extensions is just like coloring your own hair. Plus, you can maintain the same level of care for them, ensuring they stay healthy and beautiful.
            </li>
            <li>
  <strong>Worry-Free Wear</strong>
  Forget about constantly worrying if your extensions look natural. With Radiance’s human hair extensions, you can wear them with confidence. No more discomfort or fear of your extensions looking fake—just a flawless, natural look that allows you to enjoy every moment.
            </li>
          </ul>
          <p>
          Say goodbye to your old hair struggles and say hello to the world of beautiful, natural-looking extensions with Radiance!
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs: All Questions Answered!",
      description:
        "We understand that you may have many questions and concerns about hair extensions. To make things easier, we've answered some of the most common ones for you.",
      description2: "We hope this has answered your concerns and helped you make an informed decision. Visit RADIANCE for a free consultation and let us transform your hair with the best extensions!",
      faq: [
        {
          title: "Do hair extensions damage natural hair?",
          description:
            "No, hair extensions do not harm your natural hair. They are securely attached using clips, tapes, or other safe methods that do not interfere with hair growth or health. When applied and maintained properly, they seamlessly blend with your existing hair without causing any damage.",
        },
        {
          title: "What special care do hair extensions require?",
          description:
            "Since hair extensions do not receive natural oils from the scalp, they require extra care to maintain their softness and shine. Regular conditioning and proper moisturizing are essential to keep them looking voluminous and healthy.",
        },
        {
          title: "How do I choose the right color and style?",
          description:
            "Selecting the perfect shade and style can be tricky. Your hair type, texture, and length play a crucial role in determining the most suitable extensions for you. At RADIANCE, our experienced hair professionals are here to guide you and help you find the perfect match.",
        },
        {
          title: "How long can I wear hair extensions?",
          description:
            "With proper care, high-quality extensions can last for months. The duration also depends on the type of extensions you choose and how well they are maintained.",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Extension for Men's" banner="/hair-extension-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Extension for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairExtensionforMen;
